import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { StripePlugin } from '@vue-stripe/vue-stripe';
import VueCalendly from 'vue-calendly';

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

/*
const options = {
  pk: process.env.STRIPE_PUBLISHABLE_KEY,
  stripeAccount: process.env.STRIPE_ACCOUNT,
  apiVersion: process.env.API_VERSION,
  locale: process.env.LOCALE,
};
*/

const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT,
  apiVersion: process.env.VUE_APP_API_VERSION,
  locale: '',
};

Vue.prototype.$apiUrl = process.env.VUE_APP_API_URL

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(StripePlugin, options);

Vue.use(VueCalendly);


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
